import { useEffect, useState, useContext } from "react";

import { Breadcrumb, Button, Form, Input, Table, Space, Popconfirm, DatePicker} from "antd";
import { PlusOutlined,DeleteOutlined } from "@ant-design/icons";

import { Ajax } from '@/api/http.js';

import { GlobalContext } from "@/App";
import Site from "@/pages/site.js";

export default function Sites(){
    const {globalData,setGlobalData} = useContext(GlobalContext);

    const [rangeDate,setRangeDate] = useState(null);
    const [createrName,setCreaterName] = useState(null);
    const [domain,setDomain] = useState(null);    

    const [tableData,setTableData]=useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tablePageCurrent,setTablePageCurrent] = useState(1);
    const [tablePageSize,setTablePageSize] = useState(20);
    const [tableTotal,setTableTotal] = useState(0);
    const [tableSelectedRowKeys, setTableSelectedRowKeys] = useState([]);

    const [drawerVisible,setDrawerVisible] = useState(false);
    const [drawerObjId,setDrawerObjId] = useState(null);

    useEffect(()=>{
        getTableData(tablePageCurrent,tablePageSize);
    },[])

    //实际获取表格数据
    function getTableData(pageCurrent,pageSize){
        setTableLoading(true);
        setTableSelectedRowKeys([]);

        let startDate=null;
        let endDate=null;
        if(rangeDate){
            startDate=rangeDate[0].format('YYYY-MM-DD');
            endDate=rangeDate[1].format('YYYY-MM-DD');
        }

        Ajax('/site/getlist','get',{
            startDate:startDate,
            endDate:endDate,
            createrName:createrName,
            domain:domain,
            pageCurrent:pageCurrent,
            pageSize:pageSize
        },false).then(res=>{
            setTablePageCurrent(pageCurrent);
            setTablePageSize(pageSize);
            setTableData(res['data']);
            setTableTotal(res['total']);
            setTableLoading(false);
        }).catch(err=>{
            setTableLoading(false);
        })
    }

    //点击搜索按钮
    function onSearch(){
        getTableData(1,tablePageSize);
    }

    //点击分页或排序
    function onChangeTablePag(pagination, filters, sorter){
        getTableData(pagination['current'],pagination['pageSize']);
    }

    //新建
    function onAdd(){
        setDrawerVisible(true);
        setDrawerObjId(null);
    }

    //修改
    function onEdit(oId){
        setDrawerVisible(true);
        setDrawerObjId(oId);
    }

    //删除
    function onDels(){
        Ajax('/site/dels','post',{'ids':tableSelectedRowKeys},true,true,true).then(res=>{
            getTableData(tablePageCurrent,tablePageSize);
        })
    }

    //进入
    function onInsite(one){
        localStorage.setItem('nowSiteId',one.id);
        localStorage.setItem('nowSiteDomain',one.domain);
        localStorage.setItem('nowSiteServerKey',one.serverKey);

        let tmpData={...globalData};
        tmpData['nowSite']={
            'id':one.id,
			'domain':one.domain,
			'serverKey':one.serverKey
        }
        setGlobalData(tmpData);
    }
   
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>我的网站</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="inline"
                style={{margin:'20px 0 20px 0'}}
            >
                <Form.Item>
                    <DatePicker.RangePicker value={rangeDate} onChange={(v)=>setRangeDate(v)} />
                </Form.Item>
                <Form.Item>
                    <Input placeholder="创建人" value={createrName} onChange={(e)=>setCreaterName(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Input placeholder="名称" value={domain} onChange={(e)=>setDomain(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                </Form.Item>
            </Form>
            <Space style={{margin:'0 0 10px 0'}}>
                <Button type="primary" onClick={onAdd}><PlusOutlined />新建</Button>
                <Popconfirm
                    title='确定要删除吗？'
                    onConfirm={onDels}
                >
                    <Button type="primary" danger disabled={tableSelectedRowKeys.length<1}><DeleteOutlined />删除</Button>
                </Popconfirm>
            </Space>
            <Table
                rowSelection={{
                    selectedRowKeys:tableSelectedRowKeys,
                    onChange:(newKeys)=>setTableSelectedRowKeys(newKeys)
                }}
                columns={[
                    {
                        title:'id',
                        dataIndex:'id',
                        key:'id'
                    },
                    {
                        title:'域名',
                        dataIndex:'domain',
                        key:'domain'
                    },
                    {
                        title:'服务器ip',
                        dataIndex:'serverIp',
                        key:'serverIp'
                    },
                    {
                        title:'服务器密钥',
                        dataIndex:'serverKey',
                        key:'serverKey',
                        ellipsis:true,
                        width:250
                    },
                    {
                        title:'创建人',
                        dataIndex:'createrName',
                        key:'createrName'
                    },
                    {
                        title:'创建时间',
                        dataIndex:'createdTime',
                        key:'createdTime'
                    },
                    {
                        title:'操作',
                        dataIndex:'action',
                        render:(_,record)=>(
                            <Space size='middle'>
                                <a onClick={()=>onEdit(record.id)}>修改</a>
                                <a onClick={()=>onInsite(record)}>进入</a>
                            </Space>
                        )
                    }
                ]}
                rowKey='id'
                dataSource={tableData}
                pagination={{
                    current:tablePageCurrent,
                    pageSize:tablePageSize,
                    total:tableTotal,
                    showTotal:(t)=>'合计'+t
                }}
                onChange={onChangeTablePag}
                loading={tableLoading}
            />
            <Site
                visible={drawerVisible}
                onClose={()=>setDrawerVisible(false)}
                onRefresh={()=>getTableData(tablePageCurrent,tablePageSize)}
                id={drawerObjId}
            />
        </>
    )
}
