import { useEffect, useState } from "react";

import { Breadcrumb, Button, Form, Input, Table, Space, Popconfirm, DatePicker, Image} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { SiteAjax } from '@/api/http.js';

import SiteAbleSwitch from "@/components/site/ableswitch";
import SitePage from "@/components/site/page";

export default function SitePages(){
    const [rangeDate,setRangeDate] = useState(null);
    const [pageId,setPageId] = useState(null);
    const [title,setTitle] = useState(null);

    const [tableData,setTableData]=useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [tablePageCurrent,setTablePageCurrent] = useState(1);
    const [tablePageSize,setTablePageSize] = useState(20);
    const [tableTotal,setTableTotal] = useState(0);
    const [tableSelectedRowKeys, setTableSelectedRowKeys] = useState([]);

    const [drawerVisible,setDrawerVisible] = useState(false);
    const [drawerObjId,setDrawerObjId] = useState(null);

    useEffect(()=>{
        getTableData(tablePageCurrent,tablePageSize);
    },[])

    //实际获取表格数据
    function getTableData(pageCurrent,pageSize){
        setTableLoading(true);
        setTableSelectedRowKeys([]);

        let startDate=null;
        let endDate=null;
        if(rangeDate){
            startDate=rangeDate[0].format('YYYY-MM-DD');
            endDate=rangeDate[1].format('YYYY-MM-DD');
        }

        SiteAjax('/admin/page/getlist','get',{
            startDate:startDate,
            endDate:endDate,
            pageId:pageId,
            title:title,
            pageCurrent:pageCurrent,
            pageSize:pageSize
        },false).then(res=>{
            setTablePageCurrent(pageCurrent);
            setTablePageSize(pageSize);
            setTableData(res['data']);
            setTableTotal(res['total']);
            setTableLoading(false);
        }).catch(err=>{
            setTableLoading(false);
        })
    }

    //点击搜索按钮
    function onSearch(){
        getTableData(1,tablePageSize);
    }

    //点击分页或排序
    function onChangeTablePag(pagination, filters, sorter){
        getTableData(pagination['current'],pagination['pageSize']);
    }

    //新建
    function onAdd(){
        setDrawerVisible(true);
        setDrawerObjId(null);
    }

    //修改
    function onEdit(oId){
        setDrawerVisible(true);
        setDrawerObjId(oId);
    }

    //删除
    function onDels(){
        SiteAjax('/admin/page/dels','post',{'ids':tableSelectedRowKeys},true,true,true).then(res=>{
            getTableData(tablePageCurrent,tablePageSize);
        })
    }
   
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>首页</Breadcrumb.Item>
                <Breadcrumb.Item>当前网站</Breadcrumb.Item>
                <Breadcrumb.Item>资源管理</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                layout="inline"
                style={{margin:'20px 0 20px 0'}}
            >
                <Form.Item>
                    <DatePicker.RangePicker value={rangeDate} onChange={(v)=>setRangeDate(v)} />
                </Form.Item>
                <Form.Item>
                    <Input placeholder="资源id" value={pageId} onChange={(e)=>setPageId(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Input placeholder="标题" value={title} onChange={(e)=>setTitle(e.target.value)}></Input>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                </Form.Item>
            </Form>
            <Space style={{margin:'0 0 10px 0'}}>
                <Popconfirm
                    title='确定要删除吗？'
                    onConfirm={onDels}
                >
                    <Button type="primary" danger disabled={tableSelectedRowKeys.length<1}><DeleteOutlined />删除</Button>
                </Popconfirm>
            </Space>
            <Table
                rowSelection={{
                    selectedRowKeys:tableSelectedRowKeys,
                    onChange:(newKeys)=>setTableSelectedRowKeys(newKeys)
                }}
                columns={[
                    {
                        title:'id',
                        dataIndex:'id',
                        key:'id'
                    },
                    {
                        title:'类型',
                        dataIndex:'category',
                        key:'category',
                        render:(_,record)=>{
                            switch(record.category){
                                case 0:
                                    return <>帖子</>
                                case 1:
                                    return <>短视频</>
                                default:
                                    return <>未知</>
                            }
                        }
                    },
                    {
                        title:'标题',
                        dataIndex:'title',
                        key:'title'
                    },
                    {
                        title:'视频',
                        dataIndex:'video',
                        key:'video',
                        width:120,
                        render:(_,record)=>{
                            if(record.video && record.video.thumb){
                                return (
                                    <Image 
                                        src={record.video.thumb}  
                                        width={100} 
                                        height={100}
                                        preview={{
                                            destroyOnClose:true,
                                            imageRender:()=>(
                                                <video src={record.video.source} controls style={{ height: '50%' }} />
                                            )
                                        }}
                                    />
                                )
                            }
                        }
                    },
                    {
                        title:'视频属性',
                        dataIndex:'videoAttr',
                        key:'videoAttr',
                        render:(_,record)=>{
                            if(record.video && record.video.fileSize){
                                return (
                                    <>{record.video.fileSize+'mb'}<br />{record.video.fileTime+'s'}</>
                                )
                            }
                        }
                    },
                    {
                        title:'状态',
                        dataIndex:'isEnable',
                        key:'isEnable',
                        render:(_,record)=>(
                            <SiteAbleSwitch 
                                id={record.id} 
                                isChecked={record.isEnable} 
                                checkedTxt='正常' 
                                unCheckedTxt='禁用' 
                                url='/admin/page/setenable'
                            />
                        )
                    },
                    {
                        title:'创建时间',
                        dataIndex:'createdTime',
                        key:'createdTime'
                    },
                    {
                        title:'操作',
                        dataIndex:'action',
                        render:(_,record)=>(
                            <Space size='middle'>
                                <a onClick={()=>onEdit(record.id)}>修改</a>
                            </Space>
                        )
                    }
                ]}
                rowKey='id'
                dataSource={tableData}
                pagination={{
                    current:tablePageCurrent,
                    pageSize:tablePageSize,
                    total:tableTotal,
                    showTotal:(t)=>'合计'+t
                }}
                onChange={onChangeTablePag}
                loading={tableLoading}
            />
            <SitePage
                visible={drawerVisible}
                onClose={()=>setDrawerVisible(false)}
                onRefresh={()=>getTableData(tablePageCurrent,tablePageSize)}
                id={drawerObjId}
            />
        </>
    )
}
