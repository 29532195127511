import { createContext, useState } from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

//页面
import Login from '@/pages/login';
import Base from '@/pages/base';
import Index from '@/pages';

import Users from '@/pages/users';
import SysConfig from '@/pages/sysconfig';

import Pages from '@/pages/pages';
import Sites from '@/pages/sites';
import Releases from '@/pages/releases';

import SitePages from '@/pages/site/pages';
import SiteConfig from '@/pages/site/config';

//全局状态
export const GlobalContext=createContext(null);

//引入mock数据
if(process.env.NODE_ENV=='development' && true){
	require('@/mock/site/page.js');
	require('@/mock/site/config.js');

	require('@/mock/user.js');
	require('@/mock/sysconfig.js');

	require('@/mock/page.js');
	require('@/mock/site.js');
	require('@/mock/upload.js');
	require('@/mock/release.js');
}

export default function App() {
	document.title='森林CMS';

	const [globalData,setGlobalData] = useState({
		'loginUser':{
			'token':localStorage.getItem('loginUserToken',null),
			'name':localStorage.getItem('loginUserName',''),
			'category':localStorage.getItem('loginUserCategory',null)
		},
		'nowSite':{
			'id':localStorage.getItem('nowSiteId',null),
			'domain':localStorage.getItem('nowSiteDomain',null),
			'serverKey':localStorage.getItem('nowSiteServerKey',null)
		}
	});

	return (
		<ConfigProvider locale={zhCN}>
			<GlobalContext.Provider  value={{globalData,setGlobalData}}>
				<BrowserRouter>
					<Routes>
						<Route path='/login' element={<Login />}></Route>
						<Route path='/' element={<Base />}>
							<Route index element={<Index />}></Route>

							<Route path='pages' element={<Pages />}></Route>
							<Route path='sites' element={<Sites />}></Route>
							<Route path='releases' element={<Releases />}></Route>

							<Route path='users' element={<Users />}></Route>
							<Route path='sysconfig' element={<SysConfig />}></Route>

							<Route path='site/pages' element={<SitePages />}></Route>
							<Route path='site/config' element={<SiteConfig />}></Route>
						</Route>
						<Route path='*' element={<h1>404</h1>}></Route>
					</Routes>
				</BrowserRouter>
			</GlobalContext.Provider>
		</ConfigProvider>
	);
}