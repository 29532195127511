import { useState } from 'react';
import { Button, Modal, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { Ajax } from '@/api/http.js';

const MAX_FILE_SIZE_MB = 100; // 文件大小限制（100MB）

export default function AutoUpload(props){
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);

    const handleBeforeUpload = (file) => {
        const isFileSizeValid = file.size / 1024 / 1024 < MAX_FILE_SIZE_MB;

        if (!isFileSizeValid) {
            message.error(`${file.name} 文件超过了 ${MAX_FILE_SIZE_MB}MB 大小限制`);
            return false; // 阻止上传
        }

        setFileList((prevList) => [...prevList, file]);
        return false;
    };

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleUpload = async () => {
        setUploading(true);
        const remainingFiles = [];
        

        for (const file of fileList) {
            // 模拟每个文件依次上传

            try{
                const formData = new FormData();
                formData.append('file', file);

                const res = await Ajax('/upload/video','upload',formData,false,false,false);
                const url = res['data']['url'];

                await Ajax('/page/add','post',{'category':1,'title':file.name.replace('.mp4',''),'video':url},false,false,false);

                message.success(`${file.name} 文件上传成功`);
            }
            catch(err){
                message.error(`${file.name} 文件上传失败`);
                remainingFiles.push(file); // 如果上传失败，将文件保留在列表中
            }

            await delay(1000); // 上传成功后暂停1秒
        }

        setUploading(false);
        setFileList(remainingFiles);
        props.onReload();
    };

    

    return (
        <Modal
            title='批量上传视频'
            open={props.open}
            onCancel={props.onClose}
            footer={null}
            maskClosable={false}
        >
            <Upload
                multiple
                beforeUpload={handleBeforeUpload}
                fileList={fileList}
            >
                <Button icon={<UploadOutlined />}>选择视频</Button>
            </Upload>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{ marginTop: 16 }}
            >
                {uploading ? '上传中...' : '开始上传'}
            </Button>
        </Modal>
    )
}