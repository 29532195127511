import { Modal,Form, Input, message, Select } from "antd";

import { Ajax } from '@/api/http.js';
import { useEffect, useState } from "react";

export default function ReleaseToSite(props){
    const [form] = Form.useForm();

    const [sites,setSites] = useState([]);

    useEffect(()=>{
        Ajax('/site/getlist','get').then(res=>{
            let tmpSites=[];
            for(let one of res['data']){
                tmpSites.push({
                    'label':one.domain,
                    'value':one.id
                })
            }
            setSites(tmpSites);
        })
    },[])

    function onFinish(vals){
        vals['ids']=props.ids;
        Ajax('/page/release','post',vals).then(res=>{
            message.success('提交成功！');
            props.onClose();
        })
    }

    return (
        <>
            <Modal 
                title='发布到网站' 
                open={props.open}
                onOk={()=>form.submit()}
                onCancel={props.onClose}
            >
                <Form form={form} onFinish={onFinish} labelCol={{span:3}} style={{marginTop:'20px'}}>
                    <Form.Item label='网站' name='siteId' rules={[{required:true}]}>
                        <Select options={sites}></Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}