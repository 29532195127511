import { useState, useEffect } from "react";
import { Button, Form, Input, Drawer} from "antd";

import { Ajax } from '@/api/http.js';

export default function Site(props){
    const [form] = Form.useForm();
    const [title,setTitle] = useState('');

    useEffect(()=>{
        if(props.id){
            Ajax('/site/get','get',{'id':props.id}).then(res=>{
                setTitle(res['data']['name']);
                form.setFieldsValue({
                    'domain':res['data']['domain'],
                    'serverIp':res['data']['serverIp'],
                    'serverKey':res['data']['serverKey']
                })
            })
        }else{
            setTitle('新的网站');
            form.resetFields();
        }
    },[props.id])

    //保存
    const onFinish=(vals)=>{
        let url='/site/add';
        if(props.id){
            url='/site/edit';
            vals['id']=props.id;
        }

        Ajax(url,'post',vals,true,true,true).then(res=>{
            if(!props.id){
                form.resetFields();
            }
            props.onClose();
            props.onRefresh();
        })
    }

    return (
        <Drawer
            width={750}
            open={props.visible}
            onClose={props.onClose}
            destroyOnClose={true}
            title={title}
            extra={
                <Button type="primary" onClick={()=>form.submit()}>保存</Button>
            }
        >
            <Form form={form} onFinish={onFinish} labelCol={{span:4,offset:0}}>
                <Form.Item label='域名' name='domain' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='服务器ip' name='serverIp' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
                <Form.Item label='服务器密钥' name='serverKey' rules={[{required:true}]}>
                    <Input></Input>
                </Form.Item>
            </Form>
        </Drawer>
    )
}